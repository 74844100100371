import React from 'react'
import './Component.css'
function Button(props) {
  return (
    <div className='buttonMain'>
        <span>
            {props.text}
        </span>
    </div>
  )
}

export default Button