import React from 'react'
import './Component.css'
const WhiteLine = () => {
  return (
<div className="ScrollBorder">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  )
}

export default WhiteLine