import React from 'react'
import Button  from './Button'
import './Component.css'
const HeroSection = () => {
    return (
    <>
    <div className="HeroSection">
        <div className="bgImage">
        </div>
        <div className="heroText">
            <div className="MainHero">
                SELLULAR
            </div>
            <div className="subtittle">
            Grab all the <span className='span1'>college life essentials</span>, without the <span className='span2'>hassle</span>
            </div>
            <div className="heroButton">
                <Button text="Join Sellular"/>
            </div>
        </div>
    </div>
    </>
  )
}

export default HeroSection