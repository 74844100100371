import React from 'react'
import './Component.css'
import logo from '../assets/logo.svg'
const Navbar = () => {
  return (
    <div className='HeaderSection'>
        <div className="Logo">
            <img src={logo} alt="Sellular Logo" />
        </div>
        <div className="sellualarSecured">
            Sellular Secured
        </div>
    </div>
  )
}

export default Navbar