import React from 'react'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import CampusProgram from '../components/CampusProgram'
import MarketPlace from '../components/MarketPlace'
import StartOwnStore from '../components/StartOwnStore'
import Footer from '../components/Footer'

const HomePage = () => {
  return (
    <>
      <div className="LandingPage">
        <div className='Hero'>
          <Navbar />
          <HeroSection />
        </div>
        <div className='SecondSection'>
          <CampusProgram />
        </div>
        <div className='MarketPlace'>
          <MarketPlace />
        </div>
        <div className='StartOwnStore'>
          <StartOwnStore />
        </div>
        <div className='Footer'>
        <Footer />
        </div>
      </div>
    </>
  )
}

export default HomePage