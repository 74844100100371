import React from 'react'
import './Component.css'
import Categ1 from '../assets/Categ1.svg'
import Categ2 from '../assets/Categ2.svg'
import Categ3 from '../assets/Categ3.svg'
import Categ4 from '../assets/Categ4.svg'
import Categ5 from '../assets/Categ5.svg'
import Categ6 from '../assets/Categ6.svg'
import Categ7 from '../assets/Categ7.svg'
import WhiteLine from './WhiteLine'
const CategoryScroll = () => {
  const categ=[Categ1,Categ2,Categ3,Categ4,Categ5,Categ6,Categ7];
  return (
    <>
   <WhiteLine/>
    <div className='ScrollWrapper'>
      <div className="ScrollContainer">
      {categ.map((name,index)=>{
        return(
          <>
            <img src={name} alt="Categ" key={index}/>
          </>
        )
      })}
      </div>
      <div className="ScrollContainer">
      {categ.map((name,index)=>{
        return(
          <>
            <img src={name} alt="Categ" key={index}/>
          </>
        )
      })}
      </div>
      <div className="ScrollContainer">
      {categ.map((name,index)=>{
        return(
          <>
            <img src={name} alt="Categ" key={index}/>
          </>
        )
      })}
      </div>
    </div>
    <WhiteLine/>
  </>
  )
}

export default CategoryScroll