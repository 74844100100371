import React, { useEffect, useState } from 'react'
import Img1 from '../assets/Img1.svg'
import Img2 from '../assets/Img2.svg'
import Img3 from '../assets/Img3.svg'
import MobileView from './OwnStoreCarousel'
import Typewriter from 'typewriter-effect';
const StartOwnStore = () => {
    const [isDesktop, setIsDesktop] = useState(true);
    let width = window.innerWidth
    useEffect(() => {
        if (width < 768) {
            setIsDesktop(false);
        } else {
            setIsDesktop(true)
        }
    }, [width])
    return (

        <>

            {isDesktop ?
                <div className="MainContanier">
                    <div className="textcontainer">
                        <div className="container">
                            <div className="heading">
                                <div>START</div>
                                <div>YOUR OWN</div>
                                <div className="yellow">STORE</div>
                                <Typewriter
                                    options={{
                                        strings: ['It\'s easy, sustainable, and profitable'],
                                        autoStart: true,
                                        loop: true,
                                    }} />
                                {/* <div>It's easy, sustainable, and profitable</div> */}
                                <div>You'll make it happen!</div>
                            </div>
                            <div>
                                <button>
                                    Start Now
                                </button>
                            </div>
                        </div>
                        <div className="container">
                            <div className="heading">
                                <div>START</div>
                                <div>YOUR OWN</div>
                                {/* <div className={slide.Color}>{slide.Text}</div> */}
                                <div className="teal">BUSINESS</div>
                                {/* <div>{slide.caption}</div> */}
                                <Typewriter
                                    options={{
                                        strings: ['The easiest way to become a campus entrepreneur '],
                                        autoStart: true,
                                        loop: true,
                                    }} />
                                {/* <div>The easiest way to become a campus entrepreneur</div> */}
                                <div>You'll make it happen!</div>
                            </div>
                            <div>
                                <button>
                                    Start Now
                                </button>
                            </div>
                        </div>
                        <div className="container">
                            <div className="heading">
                                <div>START</div>
                                <div>YOUR OWN</div>
                                <div className="red">ADVENTURE</div>
                                <Typewriter
                                    options={{
                                        strings: ['One-stop for all the resources you need to succeed'],
                                        autoStart: true,
                                        loop: true,
                                    }} />
                                {/* <div>One-stop for all the resources you need to succeed</div> */}
                                <div>You'll make it happen!</div>
                            </div>
                            <div>
                                <button>
                                    Start Now
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="imagecontainer">
                        <div className="Img1" >
                            <img src={Img1} alt="" />
                        </div>
                        <div className="Img2">
                            <img src={Img2} alt="" />
                        </div>
                        <div className="Img3">
                            <img src={Img3} alt="" />
                        </div>
                    </div>
                </div>
                :
                <MobileView />
                }
        </>
    )
}

export default StartOwnStore
