import React from 'react'
import './Component.css'
import right from '../assets/RightAngle.svg'
import OuterCirle from '../assets/circle.svg'
// import InnerLogo from '../assets/SellularVerified.svg'
const CampusProgram = () => {
  return (
    <>
        <div className="Section2">
            <div className="shape1">
                <span></span>
            </div>
            <div className="FirstHeading">
                CAMPUS PROGRAMS
            </div>
            <div className="SecondHeading">
                SELLULAR
            </div>
            <div className="ThridHeading">
                REINVENT YOUR COLLEGE LIFESTYLE.
            </div>
            <div className="ExploreBtn">
                <button>
                    Explore Opportunities <img src={right} alt="Next Arrow" />
                </button>
            </div>
        </div>
        <div className="SellularVerified">
        <div className="linearDivison"></div>
        <div className="sellularVerifiedLogo">
            <img src={OuterCirle} alt="" />
        </div>
        </div>
    </>
  )
}

export default CampusProgram