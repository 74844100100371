import React,{useEffect, useState} from 'react'
import Img1 from '../assets/Img1.svg'
import Img2 from '../assets/Img2.svg'
import Img3 from '../assets/Img3.svg'
const OwnStoreCarousel = () => {
    const Slides=[
        {Image:Img1,Title:"STORE",Text:"It's easy, sustainable, and profitable",Color:"yellow"},
        {Image:Img2,Title:"BUSINESS",Text:"The easiest way to become a campus entrepreneur",Color:"teal"},
        {Image:Img3,Title:"ADVENTURE",Text:"One-stop for all the resources you need to succeed",Color:"red"}
    ]
    const [current,setCurrent]=useState("page1");
    const nextSlide=()=>{
        // setCurrent(current==="page1"?"page2":current==="page2"?"page3":"page1")
        current==="page1"?setCurrent("page2"):current==="page2"?setCurrent("page3"):setCurrent("page1")
    }
    useEffect(()=>{
        const timer=setTimeout(()=>{
            nextSlide();
        },2000);
        return()=>clearTimeout(timer);
    })

    return (
        <>
        <div className={`mobileOwnStore ${current}`}>
            {Slides.map((slide,index)=>{
                return(

                    <div className="Container1" key={index}>
                <div className="text">
                    <div className="heading">
                        <div>START</div>
                        <div>YOUR OWN</div>
                        <div className={slide.Color}>{slide.Title}</div>
                        <div>{slide.Text}</div>
                        <div>You'll make it happen!</div>
                    </div>
                    <div>
                        <button>
                            Start Now
                        </button>
                    </div>
                </div>
                <div className="image">
                    <img src={slide.Image} alt="" />
                </div>
                </div>
                )
            })}
        </div>
            {/* <button style={{color:'white'}} onClick={nextSlide}>Next </button> */}
    </>
    )
}

export default OwnStoreCarousel