import React from 'react'
import Logo from '../assets/logo.svg'
import Insta from '../assets/Instagram.svg'
import FB from '../assets/youtube.svg'
import Linkedin from '../assets/Linkedin.svg'
import Twitter from '../assets/Twitter.svg'
import tick from '../assets/tickMark.svg'
const Footer = () => {
    return (
        <>
            <div className="commingSoon">more parts launching soon....</div>
            <div className="Footer">
                <div className="FooterContainer">
                    <div className="FooterSection1">
                        <div className="LogoContainer">
                            <img src={Logo} alt="" />
                           <div> Sellular.club </div>
                        </div>
                        <div className="BBT">
                            <div className="header">
                                Boring But True
                            </div>
                            <div>
                                <div className='BBTItem'><img src={tick} alt="" /> Guaranty to Never Charge Interest from Students</div>
                               <div className="BBTItem"> <img src={tick} alt="" /> Connected Across 800+ Colleges in India</div>
                               <div className="BBTItem"><img src={tick} alt="" />Only Verified ✅ Students are our members</div>
                               <div className="BBTItem"><img src={tick} alt="" />24/7 Available for Ideas at idea.sellular@gmail.com</div>
                               <div className="BBTItem"><img src={tick} alt="" />Your Data is 🔒safe with us</div>
                            </div>
                        </div>
                    </div>
                    <div className="seprationLine"></div>
                    <div className="FooterSection2">
                        <div className="heading">Contact</div>
                        <div>
                            +91 8602018935<br />
                            contactus@sellular.club<br />
                            TBD
                        </div>
                        <div className="SocilaMediaIcon">
                            <div><img src={FB} alt="" /></div>
                            <div><a  href="https://x.com/SellularNetwork?s=20" target="_blank"><img src={Twitter} alt=""/></a></div>
                            <div><a href="https://www.linkedin.com/company/sellularnetworks/" target="_blank"><img src={Linkedin} alt=""/></a></div>
                            <div><a  target="_blank" href="https://www.instagram.com/sellular_networks/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="><img src={Insta} alt=""/></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer