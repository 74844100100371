import React from 'react'
import search from '../assets/Search.svg'
import CategoryScroll from './CategoryScroll'
const MarketPlace = () => {
  return (
    <>
        <div className="MarketPalceParent">
        <div className="marketPlaceSection">
            <div className="heading">
                <div>THE CAMPUS MARKETPLACE TO</div>
                <div>FIND</div>
                <div>EVERYTHING</div>
                <div>Y<span>O</span>U NEED</div>
                <div>Imagine, you get all of your College stuff all at one</div>
                <div>place!</div>
            </div>
            <div>
                <button>
                    Buy Now <img src={search} alt="" />
                </button>
            </div>
        </div>
        <div className="RightSection">
        <CategoryScroll/>
        <div className='Overlay'></div>
        </div>
        </div>
        <div className="linearDivison"></div>
    </>
  )
}

export default MarketPlace